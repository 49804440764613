import React from 'react';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
       <h1>قريباً</h1>
       <figure>
          <audio
              controls
              src="https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/SAWT/soon.wav">
          </audio>
      </figure>
      </header>
      <footer>
        <a className="email" href="mailto:info@sawt.ai">info@sawt.ai</a>
      </footer>
    </div>
  );
}

export default App;
